import React, { useState, useRef, useCallback } from "react"
import Seo from "../components/seo"
import BrandHeading from "../components/brand-heading";
import { useInstagramFeeds } from "../hooks/use-instagram-feeds";
import { StaticImage } from "gatsby-plugin-image"
export default function Gallery() {

    const [afterPage, setAfterPage] = useState("");
    const { loading, images, hasMore, afterCursor } = useInstagramFeeds(afterPage);

    const observer = useRef()

    const lastBookElementRef = useCallback(node => {
        if (loading) return
        if (observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                setAfterPage(afterCursor)
            }
        })
        if (node) observer.current.observe(node)
    }, [loading, hasMore])

    return (
        <>
            <Seo title="Gallery" />
            <BrandHeading title="gallery" />
            <div className="font-gotu text-center text-[0.83rem] tracking-wider leading-[1.6rem] text-pas-gray md:mx-5 lg:mx-0">
                <p className="mb-9">
                    Stay in the loop and join our cake-munity! Follow <a href='https://www.instagram.com/pastelerianz/' target="_blank" className='text-pas-orange cursor-pointer'>@pastelerianz</a> on Instagram and Facebook for more of our creations & promotions.
                </p>
            </div>
            <section class="overflow-hidden ">
                <div class="container mx-auto">
                    <div class="columns-1 md:columns-2 lg:columns-3 gap-1.5 space-y-1.5 p-1 text-center">
                        {/* <StaticImage src="../images/cakes/IMG_6771-5.jpg" placeholder="blurred" className="w-full" /> */}
                        
                        {/* Add more StaticImages here... */}

                        {images.map((cake, i) => {
                            if (images.length === i + 1) {
                                return <CakeImage ref={lastBookElementRef} key={i} imageUrl={cake.media_url} permalink={cake.permalink} />
                            } else {
                                return <CakeImage key={i} imageUrl={cake.media_url} permalink={cake.permalink} />
                            }
                        })}
                    </div>
                </div>
            </section>
            <div className="text-center py-10 px-5">{loading && <><span>Fetching images...</span> <ProcessIconCake /></>}</div>
        </>
    )
}

const CakeImage = React.forwardRef(({ imageUrl, permalink }, ref) => (
    <div ref={ref} class="">

        <a href={permalink} target="_blank" rel="noopener noreferrer">
            <img src={imageUrl} alt="Pasteleria Cake" className="rounded-xs" />
        </a>
    </div>
));

const ProcessIconCake = () =>
    <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 inline-block mb-1 ml-2 animate-bounce" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M21 15.546c-.523 0-1.046.151-1.5.454a2.704 2.704 0 01-3 0 2.704 2.704 0 00-3 0 2.704 2.704 0 01-3 0 2.704 2.704 0 00-3 0 2.704 2.704 0 01-3 0 2.701 2.701 0 00-1.5-.454M9 6v2m3-2v2m3-2v2M9 3h.01M12 3h.01M15 3h.01M21 21v-7a2 2 0 00-2-2H5a2 2 0 00-2 2v7h18zm-3-9v-2a2 2 0 00-2-2H8a2 2 0 00-2 2v2h12z" />
    </svg>
